/* src/components/Chatbot.css */

.chatbot-container {
    display: flex;
    flex-direction: column;
    height: 100vh; /* Ocupa toda la altura de la pantalla */
    background-color: #333333; /* Gris oscuro */
    color: white; /* Texto blanco */
    font-family: Arial, sans-serif;
  }
  
  .chat-window {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
  }
  
  .message {
    display: flex;
    margin-bottom: 10px;
  }
  
  .message.user {
    justify-content: flex-end;
  }
  
  .message.bot {
    justify-content: flex-start;
  }
  
  .message-content {
    max-width: 60%;
    padding: 10px 15px;
    border-radius: 15px;
    word-wrap: break-word;
  }
  
  .message.user .message-content {
    background-color: #333333; /* Fondo gris oscuro */
    color: white; /* Texto blanco */
    text-align: right;
    border: 1px solid #555555;
  }
  
  .message.bot .message-content {
    background-color: white; /* Fondo blanco */
    color: #333333; /* Texto gris oscuro */
    text-align: left;
    border: 1px solid #cccccc;
  }
  
  .input-area {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #333333; /* Misma que el fondo */
    border-top: 1px solid #444444;
  }
  
  .input-field {
    flex: 1;
    resize: none;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #444444; /* Gris un poco más claro */
    color: white;
    font-size: 16px;
    max-height: 150px; /* Limita la altura máxima */
    overflow-y: auto;
  }
  
  .input-field::placeholder {
    color: #cccccc;
  }
  
  .input-field:focus {
    outline: none;
  }
  
  .send-button {
    margin-left: 10px;
    padding: 10px 15px;
    background-color: #444444; /* Gris un poco más claro */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 20px;
  }
  
  .send-button:hover {
    background-color: #555555;
  }
  